import * as types from 'actions/venues/types'
import { handleActions } from 'redux-actions'

const initialState = {
  venueInfo: {},
  details: {},
  fetching: true,
  homepage: {
    list: [],
  },
  citypage: {
    list: [],
  },
  page404_serviced_offices: {
    loading: false,
    list: [],
  },
  page404_coworking_spaces: {
    loading: false,
    list: [],
  },
}

const venueReducer = handleActions(
  {
    [types.FETCH_VENUE](state) {
      return {
        ...state,
        fetching: true,
        venueInfo: {},
      }
    },
    [types.FETCHED_VENUE](state, { venueInfo }) {
      return {
        ...state,
        fetching: false,
        venueInfo,
      }
    },
    [types.FETCHED_VENUES_FAILED](state) {
      return {
        ...state,
        fetching: false,
        venueInfo: null,
      }
    },
    [types.FETCH_VENUE_DETAILS](state) {
      return {
        ...state,
        details: {},
        fetching: true,
      }
    },
    [types.FETCHED_VENUE_DETAILS](state, { details }) {
      return {
        ...state,
        details,
        fetching: false,
      }
    },
    [types.CREATE_VENUE](state) {
      return {
        ...state,
        fetching: true,
      }
    },
    [types.CREATED_VENUE](state, { venue }) {
      return {
        ...state,
        fetching: false,
        details: {
          ...state.details,
          ...venue,
        },
      }
    },

    [types.CREATE_EDIT_VENUE_FAILED](state) {
      return {
        ...state,
        fetching: false,
      }
    },
    [types.EDIT_VENUE](state) {
      return {
        ...state,
        fetching: true,
      }
    },
    [types.EDITED_VENUE](state) {
      return {
        ...state,
        fetching: false,
      }
    },
    [types.SET_ACTION](state, { payload }) {
      const action = payload.action
      const newState =
        action === 'edit'
          ? {
              ...state,
              details: {
                ...payload,
              },
              fetching: false,
            }
          : {
              ...initialState,
              fetching: false,
            }
      return newState
    },
    [types.RESET_DETAILS](state) {
      return {
        ...state,
        details: {},
      }
    },
    [types.VENUE_DISABLE_LOADING](state) {
      return {
        ...state,
        fetching: false,
      }
    },
    [types.FETCHED_VENUE_FEATURED](state, { list, page }) {
      return {
        ...state,
        [page]: {
          list,
        },
      }
    },
    [types.FETCH_VENUE_LIST](state, { payload: { page } }) {
      return {
        ...state,
        [page]: {
          list: state[page]?.list,
          loading: true,
        },
      }
    },
    [types.FETCHED_VENUE_LIST](state, { list, page }) {
      return {
        ...state,
        [page]: {
          list,
          loading: false,
        },
      }
    },
  },
  initialState
)

export default venueReducer
